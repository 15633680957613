import React from 'react';
import { BooleanField, ChipField, ShowButton, Filter, TextInput, List, Datagrid, FunctionField, TextField, DateField, ArrayField, NumberField, WrapperField, SimpleList } from 'react-admin';
import { Grid, Icon, Typography, useMediaQuery } from '@mui/material';
import orderStatuses from '../data/orderStatuses.json'
import { green, grey, red, yellow } from '@mui/material/colors';
import PeopleAltTwoToneIcon from '@mui/icons-material/PeopleAltTwoTone';
import LocalPrintshopIcon from '@mui/icons-material/LocalPrintshop';

function statusColor(status){
    switch (status) {
        case 'completed':
            return green[200];
        case 'pending':
            return red[100];
        case 'failed':
            return red[200];
        case 'processing':
            return yellow[100];
        default:
            return grey[50];
    }
}

const fieldStyle = (record) => ({
    backgroundColor: statusColor(record.status),
});

const postRowStyle = (record) => ({
    backgroundColor: statusColor(record.status),
    borderRadius: 20,
    border: "0.1px solid",
    '& .RaDatagridRow': {
        backgroundColor: 'secondary'
    },
    '& .RaDatagridHeaderRow': {
        backgroundColor: 'inherit',
    },'& .RaDatagridHeaderCell': {
        backgroundColor: 'inherit',
    },
});

const DateHeader = () => (
    <Typography variant="h4">Tilauspvm.</Typography>
);

const OrderFilter =  props => (
    <Filter {...props} variant="outlined">
        <TextInput id="search" label="Search" source="q" alwaysOn />
    </Filter>
);

const OrderIdHeader = () => (
    <Typography variant="h4">Tilausnumero</Typography>
);


const OrderStatusHeader = () => (
    <Typography variant="h4">Tilauksen tila</Typography>
);

const NameHeader = () => (
    <Typography variant="h4">Nimi</Typography>
);

const OrderPriceHeader = () => (
    <Typography variant="h4">Hinta</Typography>
);

const SourceHeader = () => (
    <Typography variant="h4">Kauppa</Typography>
);

const PaymentMethodHeader = () => (
    <Typography variant="h4">Maksutapa</Typography>
);

const PaymentDateHeader = () => (
    <Typography variant="h4">Maksupvm.</Typography>
);

const ShippingMethodHeader = () => (
    <Typography variant="h4">Toimitustapa</Typography>
);

const shippingMethod = (record) => {
    const methodId = record ? record?.shipping_lines[0]?.method_id || '' : '';
    switch (methodId) {
        case 'local_pickup':
            return 'Nouto liikkeestä';
        case 'wcv_pro_vendor_shipping':
            return 'Postitus';
        default:
            return 'Postitus';
    }
}


const postRowSx = (record, index) => ({
    borderBottom: 0.1,
});    


export const OrderListView = (props) => {
    const isSmall = useMediaQuery(theme => theme.breakpoints.down('sm'));
    const isPaid = (record) => record.date_paid !== null ? true : false;
    return (
        <List
        empty={true} 
        emptyWhileLoading
        sort={{ field: 'date_created', order: 'DESC' }}
        perPage={10} 
        filters={<OrderFilter />} 
        title="admin.kirjain.xyz :: Tilaukset" {...props}
        sx={{padding: 2, spacing: 2, marginTop: 2, marginBottom: 2, marginLeft: 2, marginRight: 2, border: "0.1px", borderRadius: "10px", boxShadow: "0px 0px 0.1px 0px #000000"}}
        >
        {isSmall ? (
        <Datagrid optimized
        bulkActionButtons={false}
        size="small"
        isRowSelectable={record => false }
        rowClick="edit" 
        rowStyle={postRowStyle}>
            <Grid container spacing={1} style={{alignContent: 'center', alignItems: 'flex-start'}}>
                <Grid item xs={12} sm={12}>
                    <OrderIdHeader/>
                    <ChipField source="id" label="Tilausnumero"/>
                </Grid>
                <Grid item xs={12} sm={12}>
                    <DateHeader/>
                    <DateField label="Tilauspvm." source="date_created" />
                </Grid>
                <Grid item xs={12} sm={12}>
                <FunctionField
                label="Monikauppiastilaus"
                variant='outlined'
                style={{fieldStyle}}
                render={
                record => record && record.multiVendorOrder ? 'Monikauppiastilaus' : ''
                }
                            />
                    <BooleanField
                        style={{padding: 2, margin: 2}}
                        looseValue={true}
                        valueLabelTrue="Kyllä"
                        valueLabelFalse="Ei"
                        FalseIcon={Icon}
                        TrueIcon={PeopleAltTwoToneIcon}
                        label="Monikauppiastilaus"
                        source="multiVendorOrder" 
                    />
                </Grid>
            
                <Grid item xs={12} sm={12} md>
                    <SourceHeader/>
                    <TextField variant='caption' label="Kauppa" source="source"/>
                </Grid>
                <Grid item xs={12} sm={12} md>
                    <PaymentMethodHeader/>
                    <TextField variant='caption' label="Maksutapa" source="payment_method_title"/>
                </Grid>
                <Grid item style={{ ...fieldStyle, display: 'flex', justifyContent: 'center', alignItems: 'center', alignContent: 'center' }}>
                    <ShippingMethodHeader sx={{ mt: -5 }}/>
                    <FunctionField
                        label="Toimitustapa"
                        variant='caption'
                        sx={{ mt: 5, ml: -13}}
                        render={
                         record => record ? `${shippingMethod(record)}` : 'Postitus'
                    }
                    />
                </Grid>
                    <Grid item xs={12} sm={12} md>
                    <OrderStatusHeader/>
                    <FunctionField
                    label="Tila"
                    variant='outlined'
                    style={{fieldStyle}}
                    render={
                    record => `${orderStatuses.find(status => status.name === record.status).translated}`}
                                />
                        <NameHeader/>
                        <WrapperField addLabel label="Tilaaja">
                            <TextField source="billing.first_name"/><span> </span>
                            <TextField source="billing.last_name"/>
                        </WrapperField>
                
                    <OrderPriceHeader/>
                    <NumberField
                    label="Hinta"
                    source="total"
                    locales="fi-FI"
                    options={{ style: 'currency', currency: 'EUR' }}/>
                    {isPaid ? ((
                    <div>
                    <PaymentDateHeader/>
                    <DateField label="Maksupvm." source="date_paid" />
                    </div>

                )) : ''}
                </Grid>
                
                <Grid item sm={12} xs sx={{borderTop: 0.1, borderTopStyle: 'dashed'}}>
                <ArrayField label="Myydyt kirjat" source="line_items">
                    <SimpleList
                        
                        primaryText={record => (
                            <Typography variant="body2">
                                Nimi: {record.name}<span><br/></span>
                                Kirjailija: {record.author}<span><br/></span>
                                Hylly: {record.shelf}<span><br/></span>
                                Sidosasu: {record.binding}<span><br/></span>
                                Myyty määrä: {record.quantity}
                            </Typography>
                        )}
                        rowSx={postRowSx}

                        />
                </ArrayField>
                </Grid>
                <Grid item xs={12} sm={12} md >
                <Typography variant="h4">Tulostusnäkymä
                <ShowButton label="Tulostusnäkymä" icon={<LocalPrintshopIcon sx={{fontSize: "large"}} />}/>
                </Typography>
            </Grid>
            </Grid>
            </Datagrid>
            ) : (
        <Datagrid optimized
        bulkActionButtons={false}
        //size="small"
        isRowSelectable={record => false }
        rowClick="edit" 
        rowStyle={postRowStyle}>
            <Grid container spacing={1} >
                <Grid item xs={12} sm={12}>
                    <OrderIdHeader/>
                    <ChipField source="id" label="Tilausnumero"/>
                </Grid>
                <Grid item xs={12} sm={12}>
                    <DateField label="Tilauspvm." source="date_created" />
                </Grid>
                <Grid item xs={12} sm={12}>
                <FunctionField
                label="Monikauppiastilaus"
                variant='outlined'
                style={{fieldStyle}}
                render={
                record => record && record.multiVendorOrder ? 'Monikauppiastilaus' : ''
                }
                            />
                    <BooleanField
                        looseValue={true}
                        valueLabelTrue="Kyllä"
                        valueLabelFalse="Ei"
                        FalseIcon={Icon}
                        TrueIcon={PeopleAltTwoToneIcon}
                        label="Monikauppiastilaus"
                        source="multiVendorOrder" 
                    />
                </Grid>
            </Grid>
            <Grid container spacing={1} >
                <Grid item >
                    <SourceHeader/>
                    <TextField variant='caption' label="Kauppa" source="source"/>
                </Grid>
                <Grid item>
                    <PaymentMethodHeader/>
                    <TextField variant='caption' label="Maksutapa" source="payment_method_title"/>
                </Grid>
                <Grid item >
                    <ShippingMethodHeader/>
                    <FunctionField
                        label="Toimitustapa"
                        variant='caption'
                        //sx={{ mt: 5, ml: -13}}
                        render={
                         record => record ? `${shippingMethod(record)}` : 'Postitus'
                    }
                                    />
                </Grid>
            </Grid>
      <Grid container spacing={1}>
            <Grid item xs={12} sm={12}>
                <OrderStatusHeader/>
                <FunctionField
                label="Tila"
                variant='outlined'
                style={{fieldStyle}}
                render={
                record => `${orderStatuses.find(status => status.name === record.status).translated}`}
                            />
            </Grid>
            <Grid item xs={12} sm={12}>
                    <NameHeader/>
                    <WrapperField addLabel label="Tilaaja">
                        <TextField source="billing.first_name"/><span> </span>
                        <TextField source="billing.last_name"/>
                    </WrapperField>
            </Grid>
            <Grid item xs={12} sm={12} >
                <OrderPriceHeader/>
                <NumberField
                label="Hinta"
                source="total"
                locales="fi-FI"
                options={{ style: 'currency', currency: 'EUR' }}/>
            </Grid>
            <Grid item xs={12} sm={12}>
            {isPaid ? (
                        <div>
                        <PaymentDateHeader/>
                        <DateField label="Maksupvm." source="date_paid" />
                        </div>
                ) : ''}
                </Grid>
            </Grid>
            <Grid container spacing={1}>  
            <Grid item xs={12} sm={12} >
                <ArrayField label="Myydyt kirjat" source="line_items">
                    <Datagrid optimized
                    xs={12} sm={12}
                    bulkActionButtons={false}
                    isRowSelectable={record => false }
                    sx={{'& .RaDatagrid-headerCell': { backgroundColor: 'inherit',},}} >
                        <TextField label="Kirjan id" source="product_id" />
                        <TextField label="Kirjan nimi" source="name" />
                        <TextField label="Tekijä" source="author"/>
                        <TextField label="Sidosasu" source="binding"/>
                        <TextField label="Hylly" source="shelf"/>
                        <TextField label="Myyty määrä" source="quantity" />
                    </Datagrid>
                </ArrayField>
            </Grid>
        
      </Grid>
      <Grid item xs={12} sm={12} md >
                <ShowButton label="Tulosta" icon={<LocalPrintshopIcon sx={{fontSize: "large"}} />}/>
            </Grid>
      </Datagrid>
        )}
    </List>
  );
}