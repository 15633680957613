import React from "react";
import {
    ArrayField,
    Datagrid,
    Show,
    NumberField,
    SimpleForm,
    TextField,
    DateField,
    FunctionField,
} from "react-admin";
import { Grid, Typography } from "@mui/material";

import vatCodes from '../data/vatCodes.json';

const vatCode = (record) => {
    const vendor = record?.vendorName;
    return vatCodes[vendor];
}


const shippingMethod = (record) => {
    switch (record?.shipping_lines[0].method_id) {
        case 'local_pickup':
            return 'Nouto liikkeestä';
        case 'wcv_pro_vendor_shipping':
            return 'Postitus';
        default:
            return 'Postitus';
    }
}


const OrderIdHeader = () => (
    <Typography variant="h3">Tilausnumero</Typography>
);

const CustomerNoteHeader = () => (
    <Typography variant="h3">Asiakkaan viesti</Typography>
);

const TotalHeader = () => (
    <Typography variant="h4">Tilauksen summa (€)</Typography>
    );
const ShippingTotalHeader = () => (
    <Typography variant="h4">Toimituskulut (€)</Typography>
);
const DateCreatedHeader = () => (
    <Typography variant="h4">Tilauspvm</Typography>
);
const PaymentMethodHeader = () => (
    <Typography variant="h4">Maksutapa</Typography>
);

const OrderItemHeader = () => (
    <Typography variant="h3">Tilatut kirjat</Typography>
);

const VatNumberHeader = () => (
    <Typography variant="h4">Y-tunnus</Typography>
);
const BillingAddressHeader = () => (
    <Typography variant="h3">Tilaaja</Typography>
);

const ShippingAddressHeader = () => (
    <Typography variant="h3">Vastaanottaja</Typography>
);


export const OrderPrintView = props => {
return (
    <Show
        sx={{
            '& .MuiCardContent-root': {
                border: 0,
                borderColor: 'white',
                boxShadow: 'none',},

            '@media print': {
                padding: 0,
                margin: 0,
                border: 0,
                boxShadow: 'none',
                
                '@page': {
                    size: 'auto',
                },
            },
        }}
        {...props}
    >
        <SimpleForm sx={{border: 0}}  toolbar={null}>
            <Grid container spacing={1} sx={{ padding: 0, margin: 0, borderBottom: 1 }}>
                <Grid item md={6} sm={6} sx={{ padding: 0.1, margin: 0.4 }}>
                    <TextField variant="h2" source="vendorName" />
                    <Grid>
                        <TextField source="vendorAddress" />
                    </Grid>
                </Grid>
                <Grid item md={3} sm={3} sx={{ padding: 0.1, margin: 0.4 }}>
                    <Typography variant="h2">KERÄILYLISTA</Typography>
                    <TextField source="source" />
                </Grid>
            </Grid>

            <Grid alignItems="flex-start" container md={12} sm={12} sx={{ padding: 0.1, margin: 0.4, borderBottom: 1 }}>
                <Grid item md={3} sm={3} sx={{ padding: 0.1, margin: 0.4 }}>
                    <BillingAddressHeader />
                    <Grid sx={{ padding: 0.1, marginTop: 1.5 }}>
                        <TextField label="Nimi" source="name" />
                    </Grid>
                    <Grid>
                        <TextField label="Yritys" source="billing.company" />
                    </Grid>
                    <Grid>
                        <TextField label="Osoite 1" source="billing.address_1" />
                        <TextField label="Osoite 2" source="billing.address_2" />
                    </Grid>
                    <Grid>
                        <TextField label="Postinumero" source="billing.postcode" /><span> </span>
                        <TextField label="Kaupunki" source="billing.city" />
                    </Grid>
                    <Grid>
                        <TextField label="Sähköposti" type="email" source="billing.email" />
                    </Grid>
                    <Grid>
                        <TextField type="tel" label="Puhelin" source="billing.phone" />
                    </Grid>
                </Grid>
                <Grid item md={3} sm={3} sx={{ padding: 0.1, margin: 0.4 }}>
                    <ShippingAddressHeader />
                    <Grid sx={{ padding: 0.1, marginTop: 1.5 }}>
                        <TextField label="Nimi" source="shippingName" />
                    </Grid>
                    <Grid>
                        <TextField label="Yritys" source="shipping.company" />
                    </Grid>
                    <Grid>
                        <TextField label="Osoite 1" source="shipping.address_1" />
                        <TextField label="Osoite 2" source="shipping.address_2" />
                    </Grid>
                    <Grid>
                        <TextField label="Postinumero" source="shipping.postcode" /><span> </span>
                        <TextField label="Kaupunki" source="shipping.city" />
                    </Grid>
                    <Grid>
                        <TextField label="Sähköposti" type="email" source="shipping.email" />
                    </Grid>
                    <Grid>
                        <TextField type="tel" label="Puhelin" source="shipping.phone" />
                    </Grid>
                </Grid>
                <Grid item md={3} sm={3} sx={{ padding: 0.1, margin: 0.1 }}>
                    <table
                        style={{
                            height: '100%',
                            width: '100%'
                        }}
                    >
                        <tr>
                            <td><OrderIdHeader /></td><td><TextField variant="h3" source="id" /></td>
                        </tr>
                        <tr>
                            <td><DateCreatedHeader /></td><td><DateField label="Tilauspvm" source="date_created" /></td>
                        </tr>
                        <tr>
                            <td><PaymentMethodHeader /></td><td><TextField label="Maksutapa" source="payment_method_title" /></td>
                        </tr>
                        <tr>
                            <td><Typography variant="h4">Toimitustapa</Typography></td><td><FunctionField render={record => `${shippingMethod(record)}`} /></td>
                        </tr>
                        <tr>
                            <td><TotalHeader /></td><td><NumberField locales="fi-FI" options={{ style: 'currency', currency: 'EUR' }} source="total" /></td>
                        </tr>
                        <tr>
                            <td><ShippingTotalHeader /></td><td><NumberField locales="fi-FI" options={{ style: 'currency', currency: 'EUR' }} source="shipping_total" /></td>
                        </tr>
                    </table>
                </Grid>
            </Grid>

            <Grid container alignContent={true} alignItems="flex-start" spacing={1}>
                <Grid md={10} sm={10} sx={{ padding: 0.1, margin: 0.4 }}>
                    <CustomerNoteHeader />
                    <TextField source="customer_note" multiline minRows={2} label="Tilauksen kommentti" />
                </Grid>
                <Grid item md={10} sm={10} sx={{ margin: 0.2 }}>
                    <OrderItemHeader />
                    <ArrayField label="Myydyt kirjat" source="line_items">
                        <Datagrid optimized bulkActionButtons={false} sx={{ textAlign: "justify" }}>

                            <TextField source="product_id" label="ID" />
                            <TextField label="Nimi" source="name" />
                            <TextField label="Tekijä" source="author" />
                            <TextField label="Sidosasu" source="binding" />
                            <TextField label="Hylly" source="shelf" />
                            <NumberField locales="fi-FI"
                                options={{ style: 'currency', currency: 'EUR' }} label="Hinta" source="price" />
                            <TextField label="Myyty määrä" source="quantity" />

                        </Datagrid>
                    </ArrayField>
                </Grid>


                <Grid container alignItems="flex-start" lg={12} md={12} sm={12} sx={{ padding: 0.1, margin: 0.4, borderTop: 1 }}>
                    <Grid item lg={6} md={6} sm={6} sx={{ padding: 0.1, margin: 0.4, }}>

                        <Grid>
                            <TextField variant="h3" source="vendorName" />
                        </Grid>
                        <Grid>
                            <TextField source="vendorAddress" />
                        </Grid>
                        <Grid>
                            <TextField source="vendorMail" />
                        </Grid>
                        <Grid>
                            <TextField type="tel" source="vendorPhone" />
                        </Grid>

                    </Grid>

                    <Grid item lg={3} md={3} sm={3} sx={{ padding: 0.1, margin: 0.4, }}>
                        <Grid>
                            <VatNumberHeader />
                            <FunctionField render={record => `${vatCode(record)}`} />
                        </Grid>
                        <Grid>
                            <Typography variant="h4">Verotus</Typography>
                            <Typography variant="caption">Marginaaliverotus, ei sisällä vähennettävää arvonlisäveroa.</Typography>
                        </Grid>
                    </Grid>

                </Grid>



            </Grid>


        </SimpleForm>

    </Show>
)
};